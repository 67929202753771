<template>
  <div >
    <div class="item_title">
      <div class="item_titleline"></div>合同管理
    </div>
    <div class="content">
      <div class="contract_tab">
        <div class="contract_tab_item active_contract_tab_item">
          用工合同
          <div class="contract_line"></div>
        </div>
        <router-link to="/workbench/workbenchPurchase">
          <div class="contract_tab_item">采购合同</div>
        </router-link>
      </div>
      <div class="contract_search">
        <a-row>
          <a-col :span="6" class="contract_search_item">
            <a-row>
              <a-col :span="6">
                <div class="contract_search_text">合同编号</div>
              </a-col>
              <a-col :span="12">
                <a-input placeholder="Basic usage" />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6" class="contract_search_item">
            <a-row>
              <a-col :span="6">
                <div class="contract_search_text">项目名称</div>
              </a-col>
              <a-col :span="12">
                <a-input placeholder="Basic usage" />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6" class="contract_search_item">
            <a-row>
              <a-col :span="6">
                <div class="contract_search_text">合同状态</div>
              </a-col>
              <a-col :span="12">
                <a-select default-value="lucy" style="width: 180px" @change="handleChange">
                  <a-select-option value="jack">Jack</a-select-option>
                  <a-select-option value="lucy">Lucy</a-select-option>
                  <a-select-option value="disabled" disabled>Disabled</a-select-option>
                  <a-select-option value="Yiminghe">yiminghe</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6" class="contract_search_item">
            <a-row>
              <a-col :span="6">
                <div class="contract_search_text">项目负责人</div>
              </a-col>
              <a-col :span="12">
                <a-select default-value="lucy" style="width: 180px" @change="handleChange">
                  <a-select-option value="jack">Jack</a-select-option>
                  <a-select-option value="lucy">Lucy</a-select-option>
                  <a-select-option value="disabled" disabled>Disabled</a-select-option>
                  <a-select-option value="Yiminghe">yiminghe</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="7" class="contract_search_item">
            <a-row>
              <a-col :span="6">
                <div class="contract_search_text">项目开始时间</div>
              </a-col>
              <a-col :span="12">
                <a-date-picker @change="onChange" style="width: 200px" />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="7" class="contract_search_item">
            <a-row>
              <a-col :span="6">
                <div class="contract_search_text" style="width: 200px">项目结束时间</div>
              </a-col>
              <a-col :span="12">
                <a-date-picker @change="onChange" />
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <div>
        <a-table :row-selection="rowSelection" :columns="columns" :data-source="data">
          <template slot="operation">
            <a-button type="link">下载合同</a-button>
            <a-button type="link">查看合同</a-button>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '序号',
    dataIndex: 'key'
  },
  {
    title: '合同编号',
    dataIndex: 'name'
  },
  {
    title: '项目名称',
    dataIndex: ''
  },

  {
    title: '项目负责人',
    dataIndex: ''
  },
  {
    title: '项目开始时间',
    dataIndex: ''
  },
  {
    title: '项目结束时间',
    dataIndex: ''
  },
  {
    title: '合同状态',
    dataIndex: ''
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    evaluate: 4
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    evaluate: 4
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    evaluate: 4
  },
  {
    key: '4',
    name: 'Disabled User',
    age: 99,
    address: 'Sidney No. 1 Lake Park',
    evaluate: 4
  }
]
export default {
  name: 'Index',
  data() {
    return {
      val: 1,
      data,
      columns
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name
          }
        })
      }
    }
  },
  methods: {
    onChange() {},
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    getTab(val) {
      this.val = val
    }
  }
}
</script>

<style scoped lang="less">
@import url('./index');
</style>
<style lang="less">
@import url('../../../common/style');
.contract__wrapper {
  height: 100%;
}
</style>

